<template>
  <div class="upload-dropzone">
    <b-button-group class="upload-view-toggle">
      <b-button :variant="theme === 'list' ? 'primary' : 'outline-primary'" @click.prevent="changeTheme('list')">
        <i class="fas fa-list"></i>
        {{ $t('List') }}
      </b-button>
      <b-button :variant="theme === 'grid' ? 'primary' : 'outline-primary'" @click.prevent="changeTheme('grid')">
        <i class="fas fa-th"></i>
        {{ $t('Grid') }}
      </b-button>
    </b-button-group>
    <VueFileAgent
      v-model="fileRecords"
      accept="image/*,video/*,.pdf,.doc,.docx,.ods,.txt,.csv,.xlsx,.odt,.zip"
      :meta="true"
      :multiple="true"
      :deletable="true"
      :theme="theme"
      :editable="true"
      :helpText="$t('Choose files or drag & drop here')"
      @rename="renameFile($event)"
      @input="filesSelected($event)"
      @beforedelete="fileDeleted($event)"
    />
  </div>
</template>

<script>
export default {
  name: 'ThuxVueFileAgent',
  data () {
    return {
      attachments: [],
      fileRecords: [],
      theme: 'list'
    }
  },
  methods: {
    changeTheme (theme) {
      this.$set(this, 'theme', theme)
    },
    getFileName (name, ext) {
      try {
        if (ext) {
          return name.split(`.${ext}`)[0].trim()
        }
      } catch {
        return name
      }
    },
    filesSelected (instancesFiles) {
      this.$set(this, 'attachments', [])
      instancesFiles = this.fileRecords.map((instanceFile, index) => {
        instanceFile.number = index
        this.attachments.push({
          number: index,
          name: this.getFileName(instanceFile.name(), instanceFile.ext),
          attachment: instanceFile.file
        })
        return instanceFile
      })
      this.$emit('update-attachments', this.attachments)
      this.$set(this, 'fileRecords', instancesFiles)
    },
    fileDeleted (instanceFile) {
      if (!instanceFile) this.$set(this, 'fileRecords', [])
      this.fileRecords.splice(instanceFile.number, 1)
      this.attachments.splice(instanceFile.number, 1)
      this.$emit('update-attachments', this.attachments)
    },
    renameFile (instanceFile) {
      const attachments = this.attachments.map((attachment) => {
        if (attachment.number === instanceFile.number) {
          attachment.attachment = instanceFile.file
          attachment.name = this.getFileName(instanceFile.name(), instanceFile.ext)
        }
        return attachment
      })
      this.$set(this, 'attachments', attachments)
      this.$emit('update-attachments', attachments)
    }
  }
}
</script>
