<template>
  <b-modal
    :ref="refId"
    :content-class="contentClass ? `${contentClass} modal-confirm` : 'modal-confirm'"
    :no-enforce-focus="true"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :size="size || 'xl'"
    :hide-footer="hideFooter"
    centered
    @hidden="confirmDialogOnHidden"
  >
    <div slot="modal-title">
      <span style="font-size: 1.563rem">{{ getTitle }}</span>
    </div>
    <slot name="body">
      <span style="font-size: 1.1rem">{{ getBody }}</span>
    </slot>
    <div slot="modal-footer">
      <slot name="buttons">
        <slot name="button-cancel">
          <b-btn
            class="btn-wide mr-2"
            @click.prevent="closeModal()"
          >
            {{ getButtonCancelText }}
          </b-btn>
        </slot>
        <slot name="button-confirm">
          <b-btn
            ref="submit"
            :variant="confirmBtnVariant ? confirmBtnVariant : 'danger'"
            class="btn-wide"
            @click.prevent="$emit('confirm-dialog-select')"
            :disabled="confirmBtnDisabled ? confirmBtnDisabled: false"
          >
            {{ getButtonConfirmText }}
          </b-btn>
        </slot>
        <slot name="button-extra" />
      </slot>
    </div>
  </b-modal>
</template>

<script>

const ConfirmDialogComponent = {
  name: 'ConfirmDialog',
  props: [
    'title',
    'body',
    'contentClass',
    'confirmBtnVariant',
    'confirmBtnDisabled',
    'callback',
    'buttonCancelText',
    'buttonConfirmText',
    'size',
    'hideFooter',
    'noCloseOnBackdrop'
  ],
  data () {
    return {
      refId: 0
    }
  },
  created () {
    this.refId = this.generateUUID()
  },
  mounted () {
    this.openModal()
  },
  computed: {
    getTitle () {
      return this.$t(this.title ? this.title : 'Confirm')
    },
    getBody () {
      return this.$t(this.body ? this.body : 'Click Confirm button to continue')
    },
    getButtonCancelText () {
      return this.$t(this.buttonCancelText || 'Cancel')
    },
    getButtonConfirmText () {
      return this.$t(this.buttonConfirmText || 'Confirm')
    }
  },
  methods: {
    openModal () {
      this.$refs[this.refId].show()
    },
    closeModal () {
      this.$refs[this.refId].hide()
    },
    confirmDialogOnHidden (evt) {
      this.$emit('confirm-dialog-close', 'confirm-dialog-close')
    },
    confirm () {
      this.callback()
      this.closeModal()
    }
  }
}
export default ConfirmDialogComponent

export const ConfirmDialogMixin = {
  data () {
    return {
      showConfirmModal: false
    }
  },
  components: {
    'confirm-dialog': ConfirmDialogComponent
  },
  methods: {
    openConfirmModal () {
      this.showConfirmModal = true
    },
    closeConfirmModal () {
      this.showConfirmModal = false
    }
  }
}
</script>
